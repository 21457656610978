import React from "react"
import "./providers.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import CustomMarkdown from "../../utils/customMarkdown"


const Providers = ({ text, image, topPadding, bottomPadding }) => {

    return (
        <>
            <div className={`providers-section ${topPadding ? ' padding-top ' : ''} ${bottomPadding ? ' padding-bottom ' : ''} lateral-padding`}>
                <div className="providers-section__content uk-align-center" data-uk-grid>
                    <div className="uk-padding-remove uk-child-width-1-2@s uk-flex-middle" data-uk-grid>

                        <CustomMarkdown children={text} className="providers-section__content__text"></CustomMarkdown>

                        <div className="providers-section__content__image ">
                            <div data-uk-slideshow="animation: slide; autoplay: true; autoplay-interval: 2000; pause-on-hover: true; ratio: 1:1;">

                                <div className="uk-position-relative uk-visible-toggle uk-light" tabIndex="-1">

                                    <ul className="uk-slideshow-items">
                                        {image.map((item, index) => {

                                            return (
                                                <li key={index}>
                                                    <GatsbyImage image={item.gatsbyImageData} className="uk-position-center" alt=""></GatsbyImage>
                                                </li>

                                            )
                                        })}
                                    </ul>

                                    <a className="uk-position-center-left uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-previous uk-slideshow-item="previous"></a>
                                    <a className="uk-position-center-right uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-next uk-slideshow-item="next"></a>

                                </div>

                                <ul className="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin"></ul>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )

}

export default Providers